import cxs from "cxs";

const baseButtonClassName = cxs({
  padding: "1rem",
  borderWidth: "1px",
  borderColor: "rgba(0, 0, 0, 0.125)",
  borderStyle: "solid",
  borderRadius: "0.25rem",
  userSelect: "none",
  outline: "none",
  ":focus": {
    position: "relative",
    boxShadow: " 0 0 0.5rem 0 rgba(0, 0, 0, 0.25)"
  }
});

const enabledButtonClassName = cxs({
  background: "#fef3ec",
  ":hover": { background: "#fcdecb" },
  ":active": { background: "#fcdecb" }
});

const disabledButtonClassName = cxs({
  color: "#666",
  background: "#fefbf9",
  ":hover": { background: "#fefbf9" },
  ":active": { background: "#fefbf9" }
});

export function Button({ className, ...props }) {
  className = `${baseButtonClassName} ${
    props.disabled ? disabledButtonClassName : enabledButtonClassName
  } ${className}`;
  return <button className={className} {...props} />;
}

const dangerLinkClassName = cxs({
  padding: "1rem",
  outline: "none",
  background: "none",
  border: "none",
  cursor: "pointer",
  textDecoration: "underline",
  color: "#ba211f",
  ":hover": { color: "#ce2423" },
  ":active": { color: "#ce2423" },
  ":focus": {
    borderRadius: "0.25rem",
    position: "relative",
    boxShadow: " 0 0 0.5rem 0 rgba(0, 0, 0, 0.25)"
  }
});

export function DangerLink({ className, ...props }) {
  className = `${dangerLinkClassName} ${className || ""}`;
  return <button className={className} {...props} />;
}
