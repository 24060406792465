import { Component, createRef } from "inferno";
import cxs from "cxs";

import { Item } from "./List";
import { Button } from "./Button";

const itemClassName = cxs({
  display: "flex",
  flexDirection: "row"
});

const inputClassName = cxs({
  padding: "1rem",
  border: "none",
  ":focus": {
    outline: "none",
    boxShadow: "inset 0 0 0.5rem 0 rgba(0, 0, 0, 0.25)"
  },
  flexGrow: 1
});

const disabledInputClassName = cxs({
  textDecoration: "line-through",
  color: "#666"
});

const countClassName = cxs({
  flexGrow: "0",
  flexBasis: "3rem",
  width: "3rem",
  padding: "1rem 0",
  textAlign: "center",
  border: "none",
  ":focus": {
    outline: "none",
    boxShadow: "inset 0 0 0.5rem 0 rgba(0, 0, 0, 0.25)"
  }
});

const timeSignClassName = cxs({
  flexGrow: "0",
  padding: "1rem 0.5rem",
  color: "#ccc"
});

const buttonClassName = cxs({
  flexGrow: "0",
  flexBasis: "2.2rem",
  borderWidth: "0 0 0 1px",
  borderRadius: "0"
});

export class FoodItem extends Component {
  titleRef = createRef();

  componentDidMount() {
    if (this.props.grabFocus && this.titleRef.current) {
      this.titleRef.current.focus();
    }
  }

  handleTitleInput = ev => {
    const { value } = this.props;
    this.props.onChange({ ...value, title: ev.target.value });
  };

  handleCountInput = ev => {
    let count = parseInt(ev.target.value, 10) || 0;
    count = count < 0 ? 0 : count;
    this.props.onChange({ ...this.props.value, count });
  };

  handleDecrementClicked = () => {
    const { value } = this.props;
    this.props.onChange({ ...value, count: value.count - 1 });
  };

  handleIncrementClicked = () => {
    const { value } = this.props;
    this.props.onChange({ ...value, count: value.count + 1 });
  };

  render() {
    const { value, grabFocus } = this.props;

    return (
      <Item
        className={itemClassName}
        role="group"
        aria-label={`Food item ${this.props.id}`}
      >
        <input
          className={`${inputClassName} ${
            value.count < 1 ? disabledInputClassName : ""
          }`}
          value={value.title}
          onInput={this.handleTitleInput}
          grabFocus={grabFocus}
          title="Name"
          ref={this.titleRef}
        />
        <span className={timeSignClassName} aria-hidden>
          ✕
        </span>
        <input
          className={countClassName}
          value={value.count === 0 ? "" : value.count}
          onInput={this.handleCountInput}
          title="Count"
        />
        <Button
          className={buttonClassName}
          onClick={this.handleIncrementClicked}
          title={`Add another ${value.title}`}
        >
          +
        </Button>
      </Item>
    );
  }
}
