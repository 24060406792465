import cxs from "cxs";

const listClassName = cxs({
  margin: "1rem",
  display: "flex",
  flexDirection: "column"
});

export function List({ className, ...props }) {
  className = `${listClassName} ${className || ""}`;
  return <div className={className} {...props} />;
}

const itemClassName = cxs({
  background: "#fff",
  borderWidth: "1px 1px 0 1px",
  borderColor: "rgba(0, 0, 0, 0.125)",
  borderStyle: "solid",
  ":first-child": {
    borderTopLeftRadius: "0.25rem",
    borderTopRightRadius: "0.25rem",
    "> :first-child": { borderTopLeftRadius: "0.25rem" },
    "> :last-child": { borderTopRightRadius: "0.25rem" }
  },
  ":last-child": {
    borderBottomLeftRadius: "0.25rem",
    borderBottomRightRadius: "0.25rem",
    borderWidth: "1px 1px 1px 1px",
    "> :first-child": { borderBottomLeftRadius: "0.25rem" },
    "> :last-child": { borderBottomRightRadius: "0.25rem" }
  }
});

export function Item({ className, ...props }) {
  className = `${itemClassName} ${className || ""}`;
  return <div className={className} {...props} />;
}
