import { Component } from "inferno";
import cxs from "cxs";

import { List } from "./List";
import { Button, DangerLink } from "./Button";
import { FoodItem } from "./FoodItem";

const emptyList = [{ title: "", count: 1 }];

export class App extends Component {
  constructor({ initialList, ...props }) {
    super(props);
    this.state = {
      list: initialList || loadListFromLocalStorage(),
      itemAdded: null
    };
  }

  updateLocalStorage = list => {
    window.localStorage.setItem("list", JSON.stringify(list));
  };

  onItemChanged = (i, item) => {
    const left = this.state.list.slice(0, i);
    const right = this.state.list.slice(i + 1);

    // keep the item if either title or count is set or if it's the last item
    const keepItem =
      item.title || item.count > 1 || i === this.state.list.length - 1;

    const list = keepItem ? [...left, item, ...right] : [...left, ...right];
    this.setState({ list, itemAdded: null });
    this.updateLocalStorage(list);
  };

  addItem = () => {
    const itemAdded = { title: "", count: 1 };
    const list = [...this.state.list, itemAdded];
    this.setState({ list, itemAdded });
    this.updateLocalStorage(list);
  };

  resetList = () => {
    if (window.confirm("Are you sure you want to clear the entire list?")) {
      this.setState({ list: emptyList });
      this.updateLocalStorage(emptyList);
    }
  };

  render() {
    const lastItem = this.state.list[this.state.list.length - 1];
    const addButtonDisabled = lastItem && lastItem.title === "";

    return (
      <Page>
        <Header>Food List</Header>
        <List>
          {this.state.list.map((item, i) => (
            <FoodItem
              key={i}
              id={i + 1}
              value={item}
              onChange={item => this.onItemChanged(i, item)}
              grabFocus={item === this.state.itemAdded}
            />
          ))}
        </List>
        <Footer>
          <DangerLink onClick={this.resetList}>Clear list</DangerLink>
          <Button onClick={this.addItem} disabled={addButtonDisabled}>
            Add another option
          </Button>
        </Footer>
      </Page>
    );
  }
}

const pageClassName = cxs({
  maxWidth: "600px",
  margin: "auto"
});

function Page(props) {
  return <div className={pageClassName} {...props} />;
}

const headerClassName = cxs({
  margin: "1.5rem 1rem",
  textAlign: "center",
  color: "#582920"
});

function Header(props) {
  return <h1 className={headerClassName} {...props} />;
}

const footerClassName = cxs({
  margin: "0.5rem 1rem",
  display: "flex",
  justifyContent: "space-evenly"
});

function Footer(props) {
  return <div className={footerClassName} {...props} />;
}

function loadListFromLocalStorage() {
  try {
    return JSON.parse(window.localStorage.getItem("list")) || emptyList;
  } catch (e) {
    return emptyList;
  }
}
