import { render } from "inferno";

import { App } from "./components/App";

render(<App />, document.getElementById("root"));

if (process.env.NODE_ENV === "development" && "hot" in module) {
  module.hot.accept();
}

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  /* eslint-disable compat/compat, no-console */
  window.addEventListener("load", () => {
    // parcel-plugin-sw-cache will generate dist/sw.js. We declare the url
    // to stop parcel from trying to resolve it as a local path.
    const url = "/service-worker.js";

    navigator.serviceWorker.register(url).then(reg => {
      reg.onupdatefound = () => {
        reg.installing.onstatechange = () => {
          if (reg.installing.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // TODO: update available
            } else {
              // TODO: sw installed and running, app now available offline
            }
          }
        };
      };
    });
  });
}
